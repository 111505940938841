import { VDirective, VServices } from '@libTs/vue-base';
import { DirectiveBinding } from 'vue/types/options';
 
export const activepage: VDirective = [
  'activepage',
  {
    bind( el: HTMLElement, binding: DirectiveBinding ) {
      let header = document.getElementById("navigation");
      let btns = header.getElementsByClassName("btn123");

      let firstNaviLink = btns[0];
      firstNaviLink.classList.add("text-color3");

      for (let i = 0; i < btns.length; i++) {
        btns[i].addEventListener("click", function() {
        let current = document.getElementsByClassName("text-color3");
        current[0].className = current[0].className.replace(" text-color3", "");
        this.className += " text-color3";
        });
      } 
    }
  },
];
