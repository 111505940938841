var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "webkit-intro-slides" },
    [
      _vm.slides.length > 1
        ? _c(
            "agile",
            {
              attrs: {
                throttleDelay: 0,
                autoplay: _vm.config.autoplay,
                autoplaySpeed: _vm.config.autoplaySpeed,
                fade: _vm.config.fade,
                speed: _vm.config.speed,
                timing: _vm.config.timing
              }
            },
            [
              _vm._l(_vm.slides, function(slide) {
                return _c("div", { staticClass: "slide grid" }, [
                  _c("div", {
                    staticClass:
                      "webkit-intro-slider-background-image col-start-1 col-end-1 row-start-1 row-end-1",
                    style: { backgroundImage: "url('" + slide.image + "')" }
                  })
                ])
              }),
              _vm._v(" "),
              _c("template", { slot: "prevButton" }, [
                _c("i", {
                  staticClass: "fal fa-arrow-left webkit-intro-slider-prev"
                })
              ]),
              _vm._v(" "),
              _c("template", { slot: "nextButton" }, [
                _c("i", {
                  staticClass: "fal fa-arrow-right webkit-intro-slider-next"
                })
              ])
            ],
            2
          )
        : _vm._t("single-image")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }