var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "webkit-gallery-container-cell" }, [
    _c(
      "div",
      { staticClass: "webkit-gallery-parent" },
      _vm._l(_vm.displayedBlocks, function(album, indexOuter) {
        return _c(
          "div",
          { key: indexOuter, staticClass: "webkit-gallery-item" },
          [
            _c(
              "a",
              {
                staticClass: "webkit-gallery-item-grid",
                attrs: {
                  href: album[0].url,
                  "data-lightbox": "album-" + indexOuter,
                  "data-title": album[0].description
                }
              },
              [
                _c("div", { staticClass: "webkit-gallery-cell-background" }),
                _vm._v(" "),
                _c("div", { staticClass: "webkit-gallery-cell-overlay" }, [
                  _c(
                    "div",
                    { staticClass: "webkit-gallery-cell-overlay-container" },
                    [
                      _vm._m(0, true),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "webkit-gallery-cell-overlay-text" },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(album.length) +
                              " Bilder\n            "
                          )
                        ]
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "webkit-gallery-cell-img" }, [
                  _c("div", {
                    staticClass: "webkit-gallery-image bg-img",
                    style: {
                      backgroundImage: "url('" + album[0].url + "')"
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", {
                  staticClass: "webkit-gallery-heading",
                  domProps: { innerHTML: _vm._s(album[0].title) }
                })
              ]
            ),
            _vm._v(" "),
            _vm._l(album, function(image, index) {
              return _c("div", { key: index }, [
                index > 0
                  ? _c("a", {
                      attrs: {
                        href: image.url,
                        "data-lightbox": "album-" + indexOuter,
                        "data-title": image.description
                      }
                    })
                  : _vm._e()
              ])
            })
          ],
          2
        )
      }),
      0
    ),
    _vm._v(" "),
    _vm.blocks.length > _vm.numberOfItemsToDisplay
      ? _c(
          "div",
          {
            staticClass: "webkit-gallery-load-more-button-container",
            on: { click: _vm.showMore }
          },
          [
            _vm.loadmorebutton
              ? _c("div", { staticClass: "webkit-btn__big" }, [
                  _vm._v("\n      " + _vm._s(_vm.loadmorebutton) + " "),
                  _c("i", { staticClass: "webkit-angleDown ml-2" })
                ])
              : _c("i", { staticClass: "webkit-btn__small webkit-plus" })
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.displayedBlocks.length == _vm.blocks.length &&
    _vm.blocks.length != _vm.initialNumberOfItemsToShow &&
    _vm.blocks.length >= _vm.numberOfItemsToDisplay
      ? _c(
          "div",
          {
            staticClass: "webkit-gallery-load-less-button-container",
            on: { click: _vm.showLess }
          },
          [
            _vm.showlessbutton
              ? _c("div", { staticClass: "webkit-btn__big" }, [
                  _vm._v("\n      " + _vm._s(_vm.showlessbutton) + " "),
                  _c("i", { staticClass: "webkit-angleUp ml-2" })
                ])
              : _c("i", { staticClass: "webkit-btn__small webkit-minus" })
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "webkit-gallery-cell-overlay-icon" }, [
      _c("i", { staticClass: "fal fa-plus" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }