var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "bone-input-text",
      class: {
        "bone-input-text-filled": _vm.focus || _vm.filled || _vm.autofilled,
        "bone-input-touched": _vm.touched,
        "bone-input-invalid": !_vm.isValid
      }
    },
    [
      _c("div", { staticClass: "bone-input-text-inner" }, [
        _c("input", {
          ref: "inputEl",
          staticClass: "bone-input-text-input",
          attrs: {
            id: "bone-text-" + _vm.name + "-" + _vm.inst,
            type: _vm.innerType,
            name: _vm.name,
            required: _vm.required,
            disabled: _vm.disabled,
            autocomplete: _vm.autocomplete
          },
          domProps: { value: _vm.innerValue },
          on: {
            input: _vm.onChangeValue,
            animationstart: _vm.checkAnimation,
            focus: function($event) {
              return _vm.onFocus($event)
            },
            blur: function($event) {
              return _vm.onBlur($event)
            }
          }
        }),
        _vm._v(" "),
        _c(
          "label",
          {
            staticClass: "bone-input-text-label",
            attrs: { for: "bone-text-" + _vm.name + "-" + _vm.inst }
          },
          [_vm._t("default")],
          2
        )
      ]),
      _vm._v(" "),
      _vm.innerInstruction
        ? _c("div", {
            staticClass: "bone-instruction",
            domProps: { innerHTML: _vm._s(_vm.innerInstruction) }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.innerError
        ? _c("div", {
            staticClass: "bone-error",
            domProps: { innerHTML: _vm._s(_vm.innerError) }
          })
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }