var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "webkit-testimonial-outer-grid" }, [
    _c(
      "div",
      { staticClass: "webkit-testimonial-cell" },
      [
        _vm.slides.length > 1
          ? _c(
              "agile",
              { attrs: { throttleDelay: 0 } },
              _vm._l(_vm.slides, function(slide, index) {
                return _c(
                  "a",
                  {
                    key: slide.title + "-" + index,
                    staticClass:
                      "webkit-testimonial-container overflow-hidden relative",
                    attrs: { href: slide.link, target: slide.target }
                  },
                  [
                    _c("div", { staticClass: "webkit-testimonial-grid" }, [
                      _c(
                        "div",
                        { staticClass: "webkit-testimonial-image-container" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "webkit-testimonial-image",
                              style: {
                                backgroundImage: "url('" + slide.image + "')"
                              }
                            },
                            [
                              _c("img", {
                                staticClass: "img-hidden",
                                attrs: { src: slide.image, alt: slide.alt }
                              }),
                              _vm._v(" "),
                              _c("div", {
                                staticClass: "webkit-testimonial-image-aspect"
                              })
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "webkit-testimonial-title" }, [
                        _vm._v(_vm._s(slide.title))
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "webkit-testimonial-text" }, [
                        _vm._v(
                          "\n            " + _vm._s(slide.text) + "\n          "
                        )
                      ]),
                      _vm._v(" "),
                      slide.link
                        ? _c(
                            "div",
                            {
                              staticClass: "webkit-testimonial-button-container"
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "webkit-testimonial-button" },
                                [
                                  slide.linkText
                                    ? _c(
                                        "div",
                                        { staticClass: "webkit-btn__big" },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(slide.linkText) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    : _c("i", {
                                        staticClass:
                                          "webkit-btn__small webkit-arrow-right"
                                      })
                                ]
                              )
                            ]
                          )
                        : _vm._e()
                    ])
                  ]
                )
              }),
              0
            )
          : _vm._t("testimonial-single")
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }