var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-grid-structure" }, [
    _c("div", { staticClass: "webkit-grid-container-cell l:my-2 my-5" }, [
      _c("div", {
        staticClass:
          "text-3xl mt-0 l:text-2xl  font-bold my-10 l:mb-6 text-color3",
        domProps: { innerHTML: _vm._s(_vm.heading) }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "test" },
        _vm._l(_vm.displayedBlocks, function(block, index) {
          return _c(
            "a",
            {
              key: index,
              staticClass: "webkit-quad-block",
              attrs: { href: block.url || false, target: block.target }
            },
            [
              _c("div", { staticClass: "webkit-quard-item-box" }, [
                _c("div", { staticClass: "overflow-hidden" }, [
                  _c("div", { staticClass: "webkit-quad-block-image-cell" }, [
                    _c("div", {}, [
                      block.image
                        ? _c("div", {
                            staticClass: "webkit-quad-block-image",
                            style: {
                              backgroundImage: "url('" + block.image + "')"
                            }
                          })
                        : _vm._e()
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "webkit-quad-block-grid-text-container" },
                  [
                    _c("div", {
                      staticClass:
                        "webkit-quad-block-title custom-text-size3 font-extrabold",
                      domProps: { innerHTML: _vm._s(block.heading) }
                    }),
                    _vm._v(" "),
                    _vm._m(0, true)
                  ]
                )
              ])
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "webkit-textGrid-load-more-button-container dienstleistunhen-load-btn"
        },
        [
          _vm.blocks.length > _vm.numberOfItemsToDisplay
            ? _c(
                "div",
                { staticClass: "flex mr-4", on: { click: _vm.showMore } },
                [
                  _vm.loadmorebutton
                    ? _c(
                        "div",
                        {
                          staticClass: "webkit-btn__big ",
                          style: { color: _vm.color, borderColor: _vm.color }
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.loadmorebutton) +
                              "\n            "
                          )
                        ]
                      )
                    : _vm._e()
                ]
              )
            : _vm._e()
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "float-right webkit-btn__small text-color3 text-sm font-bold"
      },
      [_c("i", { staticClass: "far fa-angle-right" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }