var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "webkit-textGrid-background" }, [
      _c(
        "div",
        { staticClass: "webkit-textGrid-container" },
        [
          _vm._t("heading"),
          _vm._v(" "),
          _c("div", { staticClass: "webkit-textGrid-container-cell" }, [
            _c(
              "div",
              { staticClass: "webkit-textGrid-parent" },
              _vm._l(_vm.displayedBlocks, function(block, index) {
                return _c(
                  "a",
                  {
                    key: index,
                    staticClass: "webkit-textGrid-item",
                    attrs: { href: block.url || false, target: block.target }
                  },
                  [
                    _c("div", { staticClass: "webkit-textGrid-item-grid" }, [
                      _c("div", {}, [
                        _c(
                          "div",
                          { staticClass: "trier-grid-heading text-f5" },
                          [_vm._v(_vm._s(block.heading) + " ")]
                        ),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "trier-grid-text",
                          domProps: { innerHTML: _vm._s(block.text) }
                        })
                      ])
                    ])
                  ]
                )
              }),
              0
            )
          ])
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }