<!-- this is webkit news-->
<!--buttons colors from this file are placed in _buttons.scss it is hard coded and it has to be changed-->

<template>
    <div class="main-grid-structure">
      <div class="webkit-grid-container-cell l:my-2 my-5">

      <div class="text-3xl mt-0 l:text-2xl  font-bold my-10 l:mb-6 text-color3"
          v-html="heading">
      </div> 

      <!--Contenet-->
        
          <div class="test" >
            
              <a
                class="webkit-quad-block" v-bind:key="index"
                                          v-for="(block, index) in displayedBlocks"
                                          v-bind:href="block.url || false"
                                          :target="block.target"
              >

                <div class="webkit-quard-item-box">
                  <div class="overflow-hidden">
                      <div class="webkit-quad-block-image-cell">
                          <!--img-->
                          <div class="">
                              <div class="webkit-quad-block-image" v-if="block.image"
                              v-bind:style="{ backgroundImage: 'url(\'' + block.image + '\')', }" >
                              </div>
                          </div>
                      </div>
                  </div>


                  <!-- <div class="webkit-grid-spacing-above-text"></div> -->
                  <!-- <div class="webkit-grid-heading2" v-if="block.heading2 && showdate" v-html="block.heading2"></div> -->
        
                  <div class="webkit-quad-block-grid-text-container">
                      <div class="webkit-quad-block-title custom-text-size3 font-extrabold" v-html="block.heading"> </div>
                      <!--READ MORE-->
                      <div class="float-right webkit-btn__small text-color3 text-sm font-bold" >
                        <i class="far fa-angle-right"></i>
                      </div>
                  </div>
                
                </div>
              </a>
          </div>


        <!--Load more button-->
        <div class="webkit-textGrid-load-more-button-container dienstleistunhen-load-btn">
          <div
            class="flex mr-4"
            v-if="blocks.length > numberOfItemsToDisplay"
            v-on:click="showMore"
          >
            <div v-if="loadmorebutton" class="webkit-btn__big " v-bind:style="{color: color, borderColor: color}">
              {{ loadmorebutton }}
            </div>
          </div>
<!--           <a 
            v-if="loadmorelink"
            v-bind:href="loadmorelink"
            class="flex mr-4"
          >
          </a> -->
        </div>
      </div>

    </div>
</template>

<script>
import Vue, { VNode } from 'vue';
import * as gridConfig from 'theme/modules/grid/theme.grid.config';
import { VueAgile } from 'vue-agile';

export default Vue.extend({
  name: 'WebkitNews',
  props: {
    blocks: Array,
    categories: Array,
    loadmorebutton: String,
    showdate: String,
    loadmorelink: String,
    categories: Array,
    heading:String,
    color:String,
    onlyfourcol:Boolean
  },
  components: {
    agile: VueAgile,
  },
  data() {
    return {
      numberOfItemsToDisplay: this.onlyfourcol === "1" ? 4 : 8,
      increment: gridConfig.loadMore.numberOfItemsIncrement,
      displayedBlocks: [],
      activeFilter: "",
      myOptions: {            
            responsive: [
              
                {
                    breakpoint: 1050,
                    settings: {
                        slidesToShow: 4
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 3
                    }
                }
              
            ]
        }
    };
  },
  methods: {
    showMore: function () {
      this.numberOfItemsToDisplay += this.increment;
      this.numberOfItemsToDisplay = Math.min(
        this.numberOfItemsToDisplay,
        this.blocks.length
      );
      this.displayedBlocks = this.blocks.slice(0, this.numberOfItemsToDisplay);
    },
    filterBlocks(categoryName) {
      this.displayedBlocks = this.blocks.filter(block => 
        block.category == categoryName
      ).slice(0, this.numberOfItemsToDisplay);

      this.activeFilter = categoryName;
    },
    resetFilter() {
      this.displayedBlocks = this.blocks.slice(0, this.numberOfItemsToDisplay);
      this.activeFilter = "";
    }
  },
  created() {
    this.displayedBlocks = this.blocks.slice(0, this.numberOfItemsToDisplay);
  },
});
</script>
