<template>
  <div>
    <div class="webkit-textGrid-background">
      <div class="webkit-textGrid-container">
        <slot name="heading"></slot>
        <div class="webkit-textGrid-container-cell">
          <div class="webkit-textGrid-parent">
            <a
              class="webkit-textGrid-item"
              v-bind:key="index"
              v-for="(block, index) in displayedBlocks"
              v-bind:href="block.url || false"
              :target="block.target"
            >
              <div class="webkit-textGrid-item-grid">
                <div class="">
                 
                 <div class="trier-grid-heading text-f5">{{ block.heading }} </div>   
                  <div class="trier-grid-text" v-html="block.text"></div>  
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue, { VNode } from 'vue';
import * as textGridConfig from 'theme/modules/textGrid/theme.textGrid.config';

export default Vue.extend({
  name: 'WebkitTextGrid',
  props: {
    blocks: Array,
    loadmorebutton: String,
    email: String,
  },
  data() {
    return {
      numberOfItemsToDisplay: textGridConfig.loadMore.numberOfItemsToShow,
      increment: textGridConfig.loadMore.numberOfItemsIncrement,
      displayedBlocks: [],
    };
  },
  methods: {
    showMore: function () {
      this.numberOfItemsToDisplay += this.increment;
      this.numberOfItemsToDisplay = Math.min(
        this.numberOfItemsToDisplay,
        this.blocks.length
      );
      this.displayedBlocks = this.blocks.slice(0, this.numberOfItemsToDisplay);
    },
  },
  created() {
    this.displayedBlocks = this.blocks.slice(0, this.numberOfItemsToDisplay);
  },
});
</script>
