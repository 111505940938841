var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "webkit-grid-container-cell" }, [
    _c(
      "div",
      { staticClass: "webkit-grid-parent" },
      _vm._l(_vm.displayedBlocks, function(block, index) {
        return _c(
          "a",
          {
            key: index,
            staticClass: "webkit-grid-item",
            attrs: { href: block.url || false, target: block.target }
          },
          [
            _c("div", { staticClass: "webkit-grid-item-grid" }, [
              _c("div", { staticClass: "webkit-grid-cell-background" }),
              _vm._v(" "),
              _c("div", { staticClass: "webkit-grid-cell-img" }, [
                block.image
                  ? _c("div", {
                      staticClass: "webkit-grid-image bg-img",
                      style: {
                        backgroundImage: "url('" + block.image + "')"
                      }
                    })
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "webkit-grid-spacing-above-text" }),
              _vm._v(" "),
              block.heading2 && _vm.showdate
                ? _c("div", {
                    staticClass: "webkit-grid-heading2",
                    domProps: { innerHTML: _vm._s(block.heading2) }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", {
                staticClass: "webkit-grid-heading",
                domProps: { innerHTML: _vm._s(block.heading) }
              }),
              _vm._v(" "),
              block.text
                ? _c("div", {
                    staticClass: "webkit-grid-text",
                    domProps: { innerHTML: _vm._s(block.text) }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "webkit-grid-button-cell" }, [
                block.url
                  ? _c("div", { staticClass: "webkit-grid-button" }, [
                      _vm._m(0, true)
                    ])
                  : _vm._e()
              ])
            ])
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _vm.blocks.length > _vm.numberOfItemsToDisplay
      ? _c(
          "div",
          {
            staticClass: "webkit-gallery-load-more-button-container pt-6",
            on: { click: _vm.showMore }
          },
          [
            _vm.loadmorebutton
              ? _c("div", { staticClass: "webkit-btn__big" }, [
                  _vm._v("\n      " + _vm._s(_vm.loadmorebutton) + "\n    ")
                ])
              : _c("i", { staticClass: "webkit-btn__small webkit-plus" }, [
                  _vm._v("\n      " + _vm._s(_vm.loadmorebutton) + "\n    ")
                ])
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "webkit-btn__icon" }, [
      _c("div", { staticClass: "btn-icon-text" }, [_vm._v("Weiterlesen")]),
      _vm._v(" "),
      _c("i", { staticClass: "fal fa-arrow-circle-right" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }