



























































































































import Vue from "vue";

let inst = 0;

export default Vue.extend({
  name: "BoneInputText",
  props: [
    "name",
    "type",
    "initvalue",
    "value",
    "required",
    "disabled",
    "autocomplete",
    "instruction",
    "error",
    "validator",
  ],
  data() {
    return {
      inst: inst,
      focus: false,
      touched: false,
      filled: false,
      autofilled: false,
      isValid: true,
      innerType: "type" in this ? this.type : "text",
      innerValue: this.value || this.initvalue || "",
      innerInstruction: this.instruction || "",
      innerError: this.error || "",
    };
  },
  methods: {
    onChangeValue(e: Event) {
      this.innerValue = this.$refs.inputEl.value;
      this.filled = Boolean(this.innerValue);
      this.$emit("input", this.innerValue);
    },
    checkAnimation(e: AnimationEvent) {
      if (e.animationName == "onAutoFillStart") {
        this.autofilled = true;
      } else if (e.animationName == "onAutoFillCancel") {
        this.autofilled = false;
      }
    },
    onFocus(e: Event) {
      if (!this.touched) {
        this.touched = true;
      }
    },
    onBlur(e: Event) {
      if (typeof this.required === "undefined") {
        this.isValid = true;
      } else {
        if ("validator" in this && typeof this.validator === "function") {
          try {
            this.isValid = this.validator(this.innerValue, this);
          } catch (ex) {
            console.warn(ex);
            // Fallback validator: Non-empty
            this.isValid = Boolean(this.innerValue);
          }
          console.log("onBlur, validator", this.isValid);
        } else {
          // just check for emptiness
          this.isValid = Boolean(this.innerValue);
        }
      }
    },
  },
  created() {
    ++inst;
  },
  mounted() {
    if (this.innerValue) {
      this.filled = true;
    }
  },
  watch: {
    value(val: string) {
      this.innerValue = val;
    },
  },
});
