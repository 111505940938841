



































import Vue, { VNode } from 'vue';

export default Vue.extend({
  name: 'Akkordeon',
  props: {
    heading: String,
    text: String,
  },

  data() {
    return {
      show: false,
    };
  },
  updated: function () {
    let height = this.$refs.akkordeonText
      ? this.$refs.akkordeonText.clientHeight
      : 0;
    let buttonHeight = this.$refs.akkordeonLinks
      ? this.$refs.akkordeonLinks.clientHeight
      : 0;

    if (this.show) {
      this.$refs.akkordeonContainer.style.height = height + buttonHeight + 'px';
    } else {
      this.$refs.akkordeonContainer.style.height = 0;
    }
  },
});
